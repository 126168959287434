<template>
  <base-section
    id="customers"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading :title="$t('customers_using_evaluate')" />

      <v-slide-group>
        <template v-for="(c, i) in customers">
          <v-slide-item
            :key="c.company"
            class="align-self-center"
          >
            <base-img
              :src="`/img/customers/${c.logo}`"
              color="grey"
              contain
              height="50"
              width="120"
            />
          </v-slide-item>

          <v-responsive
            v-if="i < customers.length - 1"
            :key="`divider-${c.company}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCustomers',
    data: () => ({
      customers: [
        {company: 'JM', logo: 'jm.svg'},
        {company: 'Boliden', logo: 'boliden.svg'},
        {company: 'Di Luca & Di Luca', logo: 'diluca.png'},
        {company: 'Selecta', logo: 'selecta.svg'},
        {company: 'Spendrups', logo: 'spendrups.png'},
        {company: 'Eastnine', logo: 'eastnine.png'},
      ],
    }),
  }
</script>
